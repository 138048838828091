<template>
  <section class="hero has-background-white is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="notification has-background-white has-text-centered">
          <h1 class="title">Login</h1>
          <br>

          <p>
            Your email needs to be verified
          </p>
          <br>


          <div id="firebaseui-auth-container"></div>
        </div>
        <!-- /Notification -->
      </div>
      <!-- /Container -->
    </div>
    <!-- /Hero Body -->
  </section>
</template>

<script>
//import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
//import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'

/*
var uiConfig = {
        signInSuccessUrl: '/home/',
        signInOptions: [
          // Leave the lines as is for the providers you want to offer your users.
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          //firebase.auth.FacebookAuthProvider.PROVIDER_ID,
          //firebase.auth.TwitterAuthProvider.PROVIDER_ID,
          //firebase.auth.GithubAuthProvider.PROVIDER_ID,
          firebase.auth.EmailAuthProvider.PROVIDER_ID,
          //firebase.auth.PhoneAuthProvider.PROVIDER_ID,
          //firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
        ],
        // tosUrl and privacyPolicyUrl accept either url string or a callback
        // function.
        // Terms of service url/callback.
        tosUrl: '<your-tos-url>',
        // Privacy policy url/callback.
        privacyPolicyUrl: function() {
          window.location.assign('<your-privacy-policy-url>');
        }
      };

      // Initialize the FirebaseUI Widget using Firebase.

      */
export default {
  name: "auth",



  mounted() {

  },



};
</script>